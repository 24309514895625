import axiosInstance from "../axios/axiosInstance";

export default class MediaPagamentoService{
    getAllMediaPagamentos(){
        return axiosInstance.get('/api/media-pagamento');
    }

    getAllMediaPagamentosMcc() {
        return axiosInstance.get('/api/mediaMCC');
    }
}