import React, {useState, useEffect, useRef} from 'react';
import { classNames } from 'primereact/utils';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import AlertaService from "../../service/AlertaService";
import {useHistory, useParams} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {showToast} from "../ToastManager";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {Divider} from "primereact/divider";
import {formatWhiteLabel} from "../../utilities/AlertaUtils";

const alertaService = new AlertaService(); // Create an instance of AlertaService

const CadastrarAlerta = () => {
    const history = useHistory();
    let alertaVazia = {
        id: null,
        nome: '',
        tipo: null,
        valorMaximoAlerta: 0,
        whiteLabel: null,
        periodo: null,
        periodoAtivo: false,
        merchant:null,
        merchantAtivo: false,
        desvioPadrao: null,
        desvioPadraoAtivo: false,
        mccAtivo: false,
        mcc: null,
        captureChannel: null,
    };

    const [alerta, setAlerta] = useState(alertaVazia);
    const [submitted, setSubmitted] = useState(false);
    const [loading,setLoading] = useState(false);
    const [tipoAlertas, setTipoAlertas] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [whiteLabels, setWhiteLabels] = useState([]);
    const [captureChannel, setCaptureChannel] = useState([]);
    const [debouncedMcc, setDebouncedMcc] = useState("");
    const [mccAverage, setMccAverage] = useState(null);


    const { id } = useParams();
    useEffect(() => {
        initTipoAlertas();
        initPeriodos();
        initWhiteLabel();
        initCaptureChannel();
            if(id != null){
                setLoading(true);
                alertaService.getAlertaById(id).then(data => {
                    setLoading(false);
                    alertaVazia = data.data;
                    setAlerta(alertaVazia);
                });
            }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const initTipoAlertas = () => {
        alertaService.getTiposAlerta().then(data => {
            setTipoAlertas(data.data);
        });
    }

    const initPeriodos = () => {
        alertaService.getPeriodos().then(data => {
            const periodosFormatados = data.data.map(periodo => ({
                ...periodo,
                nome: `${periodo.nome} - ${periodo.horarioInicio} à ${periodo.horarioFim}`
            }));
            setPeriodos(periodosFormatados);
        });
    };

    const initWhiteLabel = () => {
        alertaService.getWhiteLabels().then(data => {
            const formattedWhiteLabels = data.data.map(item => ({
                ...item,
                nome: formatWhiteLabel(item.nome),
            }));
            setWhiteLabels(formattedWhiteLabels);
        });
    };


    const initCaptureChannel = () => {
        alertaService.getCaptureChannel().then(data => {
            setCaptureChannel(data.data)
        })
    }

    function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    const onInputChange = (e, name, list = false, checkbox = false,) => {
        let val = (e.target && e.target.value) || '';
        if(checkbox){
            val = e.checked;
        }

        if(typeof val  == 'string'){
            val = removeAccents(val).toUpperCase();
        }
        console.log(val);
        let _alerta = { ...alerta };
        // Verifica se o nome tem um ponto
        if (name.includes('.')) {
            const [parent, child] = name.split('.'); // Divide o nome em pai e filho
            if (!_alerta[parent]) {
                _alerta[parent] = {}; // Cria o objeto pai se não existir
            }
            _alerta[parent][child] = val; // Define o valor do atributo filho
        } else {
            if(list){
                _alerta[name] =  val;
            }else{
                _alerta[name] = val; // Define o valor diretamente se não houver aninhamento
            }

        }

        setAlerta(_alerta);
    }

    const cadastrar = () => {
        setSubmitted(true);
        console.log(alerta);
        if(alerta.nome && alerta.valorMaximoAlerta && alerta.whiteLabel && alerta.captureChannel  && ( !alerta.periodoAtivo || (alerta.periodoAtivo && alerta.periodo)) && ( !alerta.merchantAtivo || (alerta.merchantAtivo && alerta.merchant)) && ( !alerta.desvioPadraoAtivo || (alerta.desvioPadraoAtivo && alerta.desvioPadrao))){
            let _alerta = { ...alerta };
            setLoading(true)

            if(_alerta.id != null){
                alertaService.editar(_alerta,_alerta.id).then(data => {
                    showToast({severity: 'success', summary: 'Alerta editada', detail: 'Alerta editada com sucesso'});
                    history.push('/cadastro/alertas/listar');
                    return;

                }, error => {
                    showToast({severity: 'error', summary: 'Erro ao editar', detail: 'Erro ao editar alerta'});
                }).finally(() => {setLoading(false)});
            }else {
                alertaService.cadastrar(_alerta).then(data => {
                    showToast({severity: 'success', summary: 'Alerta cadastrada', detail: 'Alerta cadastrada com sucesso'});
                    history.push('/cadastro/alertas/listar');
                    return;

                }, error => {
                    showToast({severity: 'error', summary: 'Erro ao cadastrar', detail: 'Erro ao cadastrar alerta'});
                }).finally(() => {setLoading(false)});
            }


        }else{
            showToast({severity: 'error', summary: 'Erro ao cadastrar', detail: 'Preencha todos os campos'});
        }

    }

    useEffect(() => {
        const handler = setTimeout(() => {
            if (alerta.mcc) {
                setDebouncedMcc(alerta.mcc);
            }
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [alerta.mcc]);

    useEffect(() => {
        if (debouncedMcc) {
            const fetchAverage = async () => {
                try {
                    const response = await alertaService.getMerchantCategoryCode(debouncedMcc);
                    setMccAverage(response.data.mediaMCC);
                } catch (error) {
                    console.error("Erro ao buscar a média do MCC:", error);
                    setMccAverage(null);
                }
            };
            fetchAverage();
        }
    }, [debouncedMcc]);


    const formatCurrency = (value) => {
        if (!value) return "R$ 0,00";
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    return (
        <div className="grid">
            <h4>Informações da alerta</h4>
            <div className="col-12">
                <div className="card">

                    <div style={{marginTop: 10}} className="p-fluid formgrid grid">

                        <div style={{marginTop: 10}} className="field col-12 md:col-6">

                        </div>
                        <div style={{marginTop: 10}} className="field col-12 md:col-6">
                        </div>
                        <div style={{marginTop: 10}} className="field col-12">
                            <span className="p-float-label">
                                <InputText value={alerta.nome} onChange={(e) => onInputChange(e, 'nome')}
                                           className={classNames({'p-invalid': submitted && !alerta.nome})} required
                                           id="nome" type="text"/>
                                <label htmlFor="nome">Nome</label>
                            </span>
                            {submitted && !alerta.nome && <small className="p-invalid">Nome é obrigatório.</small>}
                        </div>

                        <div style={{marginTop: 10}} className="field col-12 md:col-12">
                                <span className="p-float-label">
                                    <InputNumber id="valorMaximoAlerta" value={alerta.valorMaximoAlerta}
                                                 onValueChange={(e) => onInputChange(e, 'valorMaximoAlerta')}
                                                 className={classNames({'p-invalid': submitted && !alerta.valorMaximoAlerta})}
                                                 required mode="decimal" minFractionDigits={2} maxFractionDigits={2}/>
                                    <label htmlFor="valorMaximoAlerta">Valor maximo alerta</label>
                                </span>
                            {submitted && !alerta.valorMaximoAlerta &&
                                <small className="p-invalid">Valor maximo alerta é obrigatório.</small>}
                        </div>

                        <div style={{marginTop: 10}} className="field col-12 md:col-12">
                                 <span className="p-float-label">
                                    <Dropdown id="whiteLabel" value={alerta.whiteLabel}
                                              onChange={(e) => onInputChange(e, 'whiteLabel')}
                                              className={classNames({'p-invalid': submitted && !alerta.whiteLabel})}
                                              required options={whiteLabels} optionLabel="nome"
                                              placeholder=""></Dropdown>
                                    <label htmlFor="whiteLabel">White Label</label>
                                 </span>
                            {submitted && !alerta.whiteLabel &&
                                <small className="p-invalid">White Label é obrigatório.</small>}
                        </div>

                        <div style={{marginTop: 10}} className="field col-12 md:col-12">
                                 <span className="p-float-label">
                                    <Dropdown id="captureChannel" value={alerta.captureChannel}
                                              onChange={(e) => onInputChange(e, 'captureChannel')}
                                              className={classNames({'p-invalid': submitted && !alerta.captureChannel})}
                                              required options={captureChannel} optionLabel="nome"
                                              placeholder=""></Dropdown>
                                    <label htmlFor="captureChannel">Meio de Captura</label>
                                 </span>
                            {submitted && !alerta.captureChannel &&
                                <small className="p-invalid">Meio de Captura é obrigatório.</small>}
                        </div>

                        <Divider></Divider>

                        <div style={{marginTop: 18}} className="col-12 md:col-2">
                            <div className="field-checkbox">
                                <Checkbox inputId="peridoAtivo" name="periodoAtivo"
                                          checked={alerta.periodoAtivo}
                                          onChange={(e) => {
                                              onInputChange(e, 'periodoAtivo', false, true)
                                          }}/>
                                <label htmlFor="ativoOption">Periodo</label>
                            </div>
                        </div>


                        {alerta.periodoAtivo && (
                            <div style={{marginTop: 10}} className="field col-12 md:col-10">
                                 <span className="p-float-label">
                                    <Dropdown id="periodo" value={alerta.periodo}
                                              onChange={(e) => onInputChange(e, 'periodo')}
                                              className={classNames({'p-invalid': submitted && !alerta.periodo})}
                                              required options={periodos} optionLabel="nome"
                                              placeholder=""></Dropdown>
                                    <label htmlFor="tipo">Periodo</label>
                                 </span>
                                {submitted && !alerta.periodo &&
                                    <small className="p-invalid">Periodo é obrigatório.</small>}
                            </div>
                        )}

                        <Divider></Divider>

                        <div style={{marginTop: 18}} className="col-12 md:col-2">
                            <div className="field-checkbox">
                                <Checkbox inputId="merchantAtivo" name="merchantAtivo"
                                          checked={alerta.merchantAtivo}
                                          onChange={(e) => {
                                              onInputChange(e, 'merchantAtivo', false, true)
                                          }}/>
                                <label htmlFor="ativoOption">Merchant</label>
                            </div>
                        </div>


                        {alerta.merchantAtivo && (
                            <div style={{marginTop: 10}} className="field col-12 md:col-10">
                                <span className="p-float-label">
                                    <InputText value={alerta.merchant} onChange={(e) => onInputChange(e, 'merchant')}
                                               className={classNames({'p-invalid': submitted && !alerta.merchant})}
                                               required
                                               id="merchant" type="text"/>
                                    <label htmlFor="merchant">Merchant</label>
                                </span>
                                {submitted && !alerta.merchant &&
                                    <small className="p-invalid">Merchant é obrigatório.</small>}
                            </div>
                        )}

                        <Divider></Divider>

                        <div style={{marginTop: 18}} className="col-12 md:col-2">
                            <div className="field-checkbox">
                                <Checkbox
                                    inputId="mccAtivo"
                                    name="mccAtivo"
                                    checked={alerta.mccAtivo}
                                    onChange={(e) => {
                                        onInputChange(e, 'mccAtivo', false, true)
                                    }}/>
                                <label htmlFor="ativoOption">MCC</label>
                            </div>
                        </div>

                        {alerta.mccAtivo && (
                            <div style={{marginTop: 10}} className="field col-12 md:col-10">
                                <span className="p-float-label">
                                    <InputText
                                        value={alerta.mcc}
                                        onChange={(e) => onInputChange(e, 'mcc')}
                                        className={classNames({'p-invalid': submitted && !alerta.mcc})}
                                        required
                                        id="mcc"
                                        type="text"
                                    />
                                    <label htmlFor="mcc">MCC</label>
                                </span>
                                {submitted && !alerta.mcc && <small className="p-invalid">MCC é obrigatório.</small>}
                                {mccAverage !== null && (
                                    <small className="p-valid">Média do MCC: {formatCurrency(mccAverage / 100)}</small>
                                )}
                            </div>
                        )}

                        <Divider></Divider>


                        <div style={{marginTop: 18}} className="col-12 md:col-2">
                            <div className="field-checkbox">
                                <Checkbox inputId="desvioPadraoAtivo" name="desvioPadraoAtivo"
                                          checked={alerta.desvioPadraoAtivo}
                                          onChange={(e) => {
                                              onInputChange(e, 'desvioPadraoAtivo', false, true)
                                          }}/>
                                <label htmlFor="ativoOption">Desvio Padrão</label>
                            </div>
                        </div>


                        {alerta.desvioPadraoAtivo && (
                            <div style={{marginTop: 10}} className="field col-12 md:col-10">
                                <span className="p-float-label">
                                    <InputNumber value={alerta.desvioPadrao} prefix="%" locale="en-US"
                                                 minFractionDigits={2}
                                                 onValueChange={(e) => onInputChange(e, 'desvioPadrao')}
                                                 className={classNames({'p-invalid': submitted && !alerta.desvioPadrao})}
                                                 required
                                                 id="desvioPadrao" type="text"/>
                                    <label htmlFor="desvioPadrao">Desvio padrão</label>
                                </span>
                                {submitted && !alerta.desvioPadrao &&
                                    <small className="p-invalid">Desvio padrão é obrigatório.</small>}
                            </div>
                        )}

                        <div className="col-12 md:col-6">
                        </div>

                        <Button label={id != null ? 'Atualizar' : 'Cadastrar'} loading={loading}
                                style={{width: '100%', marginTop: 10}}
                                onClick={cadastrar} className="mr-2 mb-2"></Button>
                    </div>
                </div>
            </div>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CadastrarAlerta, comparisonFn);
