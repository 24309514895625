import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TaxaService from "../../service/TaxaService";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { showToast } from "../ToastManager";

const taxaService = new TaxaService();

const ListarTaxa = () => {
    const [taxas, setTaxas] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        initTaxas();
    }, []);

    const initTaxas = () => {
        setLoading(true);
        taxaService.getTaxas()
            .then(data => {
                setTaxas(data.data);
                setLoading(false);
            }).catch(error => {
                console.log("Erro ao carregar taxas", error);
                setLoading(false);
        });
    }



    const goToRegister = () => {
        history.push('/cadastro/taxas/cadastrar');
    };

    const editarTaxa = (taxaPraEditar) => {
        history.push(`/cadastro/taxas/editar/${taxaPraEditar.idString}`);
    };

    const formatPercentage = (value) => {
        if (value != null) {
            return `${Number(value).toFixed(2).replace('.', ',')}%`;
        } else {
            return "";
        }
    };

    const percentBody = useCallback((rowData) => {
        if (rowData && rowData.taxa != null) {
            return <span>{formatPercentage(rowData.taxa)}</span>;
        }
        return <span>-</span>; // Valor padrão se taxa for nula
    }, []);

    const opcoesBody = useCallback((rowData) => {
        return (
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button icon="pi pi-pencil" onClick={() => { editarTaxa(rowData) }} className="p-button-rounded mr-2 mb-2" />
            </span>
        );
    }, []);

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <Button loading={loading} label="Cadastrar Taxa" onClick={goToRegister} className="mr-2 mb-2" />
                <div className="card">
                    <h5>Taxas Yuzer</h5>
                    <DataTable
                        value={taxas}
                        paginator
                        showGridlines
                        rows={10}
                        dataKey="id"
                        className="p-datatable-gridlines"
                        loading={loading}
                        responsiveLayout="scroll"
                        emptyMessage="Nenhuma taxa encontrada."
                    >
                        <Column sortable field="merchantId" header="Código do EC" filter filterPlaceholder="Buscar por Código" style={{ minWidth: '12rem' }} />
                        <Column sortable field="taxa" body={percentBody} header="Taxa" style={{ minWidth: '12rem' }} />
                        <Column header="Ações" body={opcoesBody} style={{ width: '8rem' }} />
                    </DataTable>

                </div>
            </div>
        </div>
    );
};

export default ListarTaxa;
