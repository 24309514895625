import axiosInstance from "../axios/axiosInstance";
import axios from "axios";

export default class TaxaService{
    getTaxas() {
        return axiosInstance.get('api/taxa-pix-yuzer/all')
    }

    getTaxaById(id) {
        return axiosInstance.get('api/taxa-pix-yuzer/' + id);
    }
    cadastrar(data) {
        return axiosInstance.post('api/taxa-pix-yuzer', data)
    }
    editar(id, data) {
        return axiosInstance.put('api/taxa-pix-yuzer/'+id , data)
    }
}