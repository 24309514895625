export default class RoleService {

    static isAdmin() {
        return true;
    }

    static isWhiteLabel() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user === null) {
            return false;
        }
        if (user.nivelAcesso.id == 5) {
            return true;
        } else {
            return false;
        }
    }

    static isColaborador() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user === null) {
            return false;
        }
        if (user.nivelAcesso.id != 1 && user.nivelAcesso.id != 5) {
            return true;
        } else {
            return false;
        }
    }


    menuItems() {
        let menu = [];

        menu.push({label: 'Dashboard', icon: 'pi pi-home', to: '/dashboard'});
        menu.push({label: 'Cadastros', icon: 'pi pi-pencil', items: []});
        menu[menu.length - 1].items.push({label: 'Alertas', icon: 'pi pi-briefcase', to: '/cadastro/alertas/listar'});
        menu.push({
            label: 'Médias', icon: 'pi pi-sort', items:
            [
                {label: 'Médias - Merchant', to: '/medias'},
                {label: 'Médias - MCC', to: '/mediasMcc'}
            ]
            });
        menu.push({
            label: 'Notificações', icon: 'pi pi-bell', items:
                [
                    {label: 'Notificações - Gerais', to: '/notificacoes'},
                    {label: 'Notificações - Link', to: '/pagamento/digital'},
                    {label: 'Notificações - 13x-21x', to: '/pagamento/parcelado'}
                ]
        });
        menu.push({
            label: 'Bloqueadas', icon: 'pi pi-ban', items:
                [
                    {label: 'Bloqueadas - Gerais', to: '/bloqueadas'},
                    {label: 'Bloquadas - Link', to: '/bloqueadas/digital'},
                    {label: 'Bloqueadas - 13x-21x', to: '/bloqueadas/parceladas'},
                ]
        });
        menu.push({
            label: 'White Label', icon: 'pi pi-briefcase', items:
                [
                    {
                        label: 'Notificacoes',
                        icon: 'pi pi-bell',
                        items: [
                            {label: 'Gerais', to: '/notificacao/whitelabel'},
                            //{label: 'Link', to: '/pagamentos/digital/whitelabel'},
                            {label: '13x-21x', to: '/pagamentos/parcelado/whitelabel'},
                        ]
                    },
                    {
                        label: 'Bloqueadas',
                        icon: 'pi pi-ban',
                        items: [
                            {label: 'Gerais', to: '/bloqueado/whitelabel'},
                            //{label: 'Link', to: '/bloqueada/digital/whitelabel'},
                            {label: '13x-21x', to: '/bloqueada/parceladas/whitelabel'},
                        ]
                    }
                ]
        });
        menu.push({label: 'Taxas PIX - Yuzer', icon: 'pi pi-percentage', to: '/cadastro/taxas/listar'});
        return menu;
    }

}
