import React, {useState, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {useHistory, useParams} from "react-router-dom";
import {showToast} from "../ToastManager";
import { classNames } from 'primereact/utils';
import {InputText} from "primereact/inputtext";
import TaxaService from "../../service/TaxaService";

const taxaService = new TaxaService();
const CadastrarTaxa = () => {
    const history = useHistory();
    let taxaVazia = {
        id: null,
        merchantId: '',
        taxa: 0.0,
        idString: '',
    }
    const [taxa, setTaxa] = useState(taxaVazia);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        if (id != null) {
            setLoading(true);
            taxaService.getTaxaById(id).then(data => {
                setLoading(false);
                taxaVazia = data.data;
                setTaxa(taxaVazia);
            })
        }
    }, []);
    const onInputChange = (e, name) => {
        let val = e.target.value;

        // Apenas converte para número na hora do cadastro ou saída do campo
        if (name === 'taxa') {
            val = val.replace(',', '.'); // Permite digitação com vírgula
        }

        let _taxa = { ...taxa };
        _taxa[name] = val; // Armazena como string enquanto o usuário digita

        setTaxa(_taxa);
    };
    const onBlurTaxa = () => {
        let _taxa = { ...taxa };
        _taxa.taxa = parseFloat(taxa.taxa.replace(',', '.')) || 0.0;
        setTaxa(_taxa);
    };


    const cadastrar = () => {
        setSubmitted(true);
        console.log(taxa);
        if (taxa.merchantId && taxa.taxa) {
            let _taxa = {...taxa};
            setLoading(true);

            if (_taxa.id != null) {
                taxaService.editar(id, _taxa).then(data => {
                    console.log(taxa);
                    showToast({severity: 'success', summary: 'Taxa editada', detail: 'Taxa editada com sucesso!'});
                    history.push('/cadastro/taxas/listar');
                    return;
                }, error => {
                    showToast({severity: 'error', summary: 'Erro ao editar', detail: 'Erro ao editar a taxa!'});
                    console.log(error);
                    history.push('/cadastro/taxas/listar');
                    return;
                }).finally(() => {
                    setLoading(false)
                });
            }else {
                taxaService.cadastrar(_taxa).then(data => {
                    showToast({severity: 'success', summary:'Taxa cadastrada', detail: 'Taxa cadastrada com sucesso!'});
                    history.push('/cadastro/taxas/listar');
                    return;
                }, error =>{
                    showToast({severity: 'error', summary:'Erro ao cadastrar', detail:'Erro ao cadastrar a taxa!'});
                }).finally(() => {
                    setLoading(false);
                })
            }
        }else {
            showToast({severity: 'error', summary: 'Erro ao cadastrar', detail: 'Preencha todos os campos!'});
        }
    }

    return (
        <div className="grid">
            <h4>Cadastrar Taxa PIX Personalizada</h4>
            <div className="col-12">
                <div className="card">
                    <div style={{marginTop: 10}} className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6" style={{marginTop: 10}}/>
                        <div style={{marginTop: 10}} className="field col-12">
                            <span className="p-float-label">
                            <InputText className={classNames({'p-invalid': submitted && !taxa.merchantId})} required
                                       id="merchantId" type="text" value={taxa.merchantId}
                                       onChange={(e) => onInputChange(e,'merchantId')}/>
                            <label htmlFor="merchantId">Código do EC</label>

                                {submitted && !taxa.merchantId &&
                                    <small className="p-invalid">Código do EC é obrigatório!</small>}
                        </span>
                        </div>
                        <div style={{marginTop: 10}} className="field col-12">
                            <span className="p-float-label">
                                <InputText
                                    id="taxa"
                                    value={taxa.taxa} // Mostra o valor digitado como string
                                    onChange={(e) => onInputChange(e, 'taxa')} // Armazena o valor enquanto o usuário digita
                                    onBlur={onBlurTaxa} // Formata o valor como número ao sair do campo
                                    required
                                />
                                <label htmlFor="taxa">Taxa PIX</label>
                            </span>
                            {submitted && !taxa.taxa &&
                                <small className="p-invalid">Taxa PIX é obrigatória!</small>}
                        </div>
                        <div className="col-12 md:col-6">
                        </div>

                        <Button label={id != null ? 'Atualizar' : 'Cadastrar'} loading={loading}
                                style={{width: '100%', marginTop: 10}}
                                onClick={cadastrar} className="mr-2 mb-2"></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(CadastrarTaxa, comparisonFn);