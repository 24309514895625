import React, {useState, useEffect, useRef} from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ToggleButton } from 'primereact/togglebutton';
import { Rating } from 'primereact/rating';
import {useHistory} from "react-router-dom";
import {Checkbox} from "primereact/checkbox";
import {Dialog} from "primereact/dialog";
import {showToast} from "../ToastManager";
import AlertaService from "../../service/AlertaService";
import {Image} from "primereact/image";
import {FileUpload} from "primereact/fileupload";
import RoleService from "../../service/RoleService";
import {formatWhiteLabel} from "../../utilities/AlertaUtils";

const alertaService = new AlertaService(); // Create an instance of AlertaService
const roleService = new RoleService();
const ListarAlerta = () => {
    const [alertas, setAlertas] = useState([]);
    const [filters1, setFilters1] = useState(null);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const history = useHistory();
    const [deleteAlertaDialog, setDeleteAlertaDialog] = useState(false);
    const [alertaParaDeletar, setAlertaParaDeletar] = useState(null);
    const fileUploadRef = useRef(null);

    useEffect(() => {
        initFilters1();
        initAlertas();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const initAlertas = () => {
        setLoading1(true);
        alertaService.getAlertas().then(data => {
            const formattedData = data.data.map(alerta => ({
                ...alerta,
                whiteLabelNome: alerta.whiteLabel?.nome || '' // Adiciona campo desnormalizado
            }));
            setAlertas(formattedData);
            setLoading1(false);
        });
    };

    const initFilters1 = () => {
        setFilters1({
            'nome': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]   },
            'codigo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'valorMaximoAlerta': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'marca.nomeMarca': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'modelo.nome': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'whiteLabel.nome': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]   },
            'captureChannel.nome': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]   },
        });
    }

    const irParaCadastro = () => {
        history.push('/cadastro/alertas/cadastrar');
    }

    const cadastroEmMassa = (e) => {
        setLoading1(true);
        fileUploadRef.current.clear();
        alertaService.cadastrarEmMassa(e.files[0]).then(() => {
            setLoading1(false)
            showToast({severity:'success', summary:'Sucesso', detail:'Alertas cadastrados com sucesso'});
            initAlertas();

        });
    }

    const abreModalDeletar = (alerta) => {
        console.log(alerta);
        setAlertaParaDeletar(alerta);
        setDeleteAlertaDialog(true);
    }

    const handleDeleteAlerta = () => {
        setLoading1(true)
        alertaService.deleteAlerta(alertaParaDeletar.id).then(() => {
            showToast({severity:'success', summary:'Sucesso', detail:'Alerta deletado com sucesso'});
            setLoading1(false);
            hideModalDeletar();
            initAlertas();
        }, error => {
            console.log(error.response);
            if(error.response.status === 424){
                showToast({severity:'error', summary:'Erro', detail:error.response.data.mensagem});
                setLoading1(false);
                hideModalDeletar();
            }
        });
    }

    const editarAlerta = (alertaParaEditar) => {
        history.push('/cadastro/alertas/editar/' + alertaParaEditar.id);
    }

    const hideModalDeletar = () => {
        setDeleteAlertaDialog(false);
        setAlertaParaDeletar(null);
    }

    const ativoBody = (rowData) => {
        return <span className="p-inputgroup-addon p-inputgroup-addon-checkbox"><Checkbox checked={rowData.ativo} disabled binary/></span>
    }
    const familiaBody = (rowData) => {
        return <span className="p-inputgroup-addon p-inputgroup-addon-checkbox">
            {rowData.familiaList.map((familia, index) => {return '['+familia.nomeFamilia+'] '})}
        </span>
    }
    const fotoBody = (rowData) => {
        return <span className="p-inputgroup-addon p-inputgroup-addon-checkbox">
            {(rowData.foto) && <Image src={rowData.foto} alt="Image"
                        width="60" preview />
            }
        </span>
    }

    const precoBody = (rowData) => {
        return <span>{formatCurrency(rowData.valorMaximoAlerta)}</span>
    }

    const formatCurrency = (value) => {
        if(value != null) {
            return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        }else{
            return "";
        }
    }

    const opcoesBody = (rowData) => {
        return <span style={{display:'flex',justifyContent:'space-between'}}>
             <Button icon="pi pi-trash" onClick={ () => { abreModalDeletar(rowData)}} style={{backgroundColor:'red', borderColor:'red'}} className="p-button-rounded mr-2 mb-2" />
             <Button icon="pi pi-pencil" onClick={ () => {editarAlerta(rowData)}} className="p-button-rounded  mr-2 mb-2" />
        </span>
    }

    const familiaFilter = (value) => {
      console.log(value);
    };

    const deleteAlertaDialogFooter = (
        <>
            <Button loading={loading1} label="Não" icon="pi pi-times" className="p-button-text" onClick={hideModalDeletar} />
            <Button loading={loading1} label="Sim" icon="pi pi-check" className="p-button-text" onClick={handleDeleteAlerta} />
        </>
    );

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <Button  loading={loading1} label="Cadastrar" onClick={irParaCadastro} className="mr-2 mb-2"></Button>

                <div className="card">
                <h5>Alertas</h5>
                    <DataTable value={alertas} paginator className="p-datatable-gridlines" showGridlines rows={10}
                               dataKey="id" filters={filters1} filterDisplay="menu" loading={loading1} responsiveLayout="scroll"
                               emptyMessage="Nenhuma alerta cadastrado.">
                        <Column sortable field="nome" header="Nome" filter filterPlaceholder="Procurar por nome" style={{ minWidth: '12rem' }} />
                        <Column sortable field="tipo.nome" header="Tipo" filter filterPlaceholder="Procurar por tipo" style={{ minWidth: '12rem' }} />
                        <Column sortable field="valorMaximoAlerta" body={precoBody}  header="Valor Maximo" filter filterPlaceholder="Procurar por valor maximo" style={{ minWidth: '12rem' }} />
                        <Column
                            header="Periodo"
                            style={{ minWidth: '12rem' }}
                            body={(rowData) => {
                            const periodo = rowData.periodo;
                            return periodo
                                ? `${periodo.nome} - ${periodo.horarioInicio} à ${periodo.horarioFim}`
                                : "";
                        }}
                            />
                        <Column
                            sortable
                            field="whiteLabelNome"
                            header="White Label"
                            filter
                            filterPlaceholder="Procurar por White Label"
                            style={{ minWidth: '12rem' }}
                            body={(rowData) => formatWhiteLabel(rowData.whiteLabelNome)} // Aplica a formatação
                        />
                        <Column sortable field="captureChannel.nome" header="Meio de Captura" filter filterPlaceholder="Procurar por Meio de Captura" style={{ minWidth: '12rem' }} />
                        <Column   header="Opções" body={opcoesBody} style={{ width: '8rem' }} />
                    </DataTable>
                </div>
            </div>

            <Dialog visible={deleteAlertaDialog} style={{ width: '450px' }} header="Deletar" modal footer={deleteAlertaDialogFooter} onHide={hideModalDeletar}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                     <span>Tem certeza que deseja deletar a alerta selecionada?</span>
                </div>
            </Dialog>
        </div>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ListarAlerta, comparisonFn);
